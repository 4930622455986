<template>
    <el-container>
        <Menu></Menu>
        <el-container direction="vertical">
            <header-component></header-component>
            <el-main> <router-view class="page"></router-view></el-main>
        </el-container>
    </el-container>
</template>

<script>
import HeaderComponent from "./header.vue";
import Menu from "./menu";

export default {
    components: {
        HeaderComponent,
        Menu,
    },
};
</script>

<style lang="scss">
.el-container {
    height: 100vh;
}
.page {
    overflow-y: scroll;
}

/* 这里可以添加一些全局或者特定的样式 */
</style>
