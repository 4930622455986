<!--  -->
<template>
    <el-aside width="200px">
        <div class="logo-container">
            <img src="../assets/user_avatar.jpg" alt="Logo" class="logo" />
            <div class="site-name">{{ title }}</div>
        </div>
        <el-menu :default-active="$route.path" class="el-menu-vertical-demo">
            <template v-for="route in filteredRoutes">
                <!-- 如果一级菜单隐藏，但有子菜单，仅渲染子菜单 -->
                <template v-if="route.hidden && route.children">
                    <el-menu-item
                        v-for="child in route.children"
                        :key="child.path"
                        :index="child.path"
                        @click="handleSelect(child.path)"
                    >
                        <i :class="child.meta.icon"></i>
                        <span slot="title">{{ child.meta.title }}</span>
                    </el-menu-item>
                </template>

                <!-- 如果一级菜单未隐藏，正常渲染 -->
                <el-submenu v-else-if="route.children && route.children.length" :key="route.path" :index="route.path">
                    <template slot="title">
                        <i :class="route.meta.icon"></i>
                        <span>{{ route.meta.title }}</span>
                    </template>
                    <el-menu-item
                        v-for="child in route.children"
                        :key="child.path"
                        :index="child.path"
                        @click="handleSelect(child.path)"
                    >
                        <i :class="child.meta.icon"> </i>
                        <span slot="title">{{ child.meta.title }}</span>
                    </el-menu-item>
                </el-submenu>

                <!-- 渲染没有子菜单的一级菜单 -->
                <el-menu-item
                    v-else
                    :key="route.path"
                    :index="route.path"
                    @click="handleSelect(route.path)"
                >
                    <i :class="route.meta.icon"></i>
                    <span slot="title">{{ route.meta.title }}</span>
                </el-menu-item>
            </template>
        </el-menu>
    </el-aside>
</template>

<script>
import { routes } from "../router/index";

export default {
    name: "Menu",
    data() {
        return {
            routes,
            title: process.env.VUE_APP_TITLE,
        };
    },

    computed: {
        filteredRoutes() {
            // 过滤掉不需要显示在菜单中的路由
            return this.routes;
        },
    },

    methods: {
        handleSelect(path) {
            if (this.$route.path !== path) {
                this.$router.push(path);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.is-active {
    color: #0092ff;
}
.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-bottom: 1px solid #ebeef5;
}

.logo {
    width: 40px; /* Logo 的尺寸，根据需要调整 */
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
}

.site-name {
    font-size: 16px;
    color: #333;
    margin: 0; /* 移除默认的 margin */
}
</style>
