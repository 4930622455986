<template>
    <div class="header">
        <div class="header-content">
            <!-- 在这里可以放置其他头部内容，如logo或导航项 -->

            <!-- 用户头像和下拉菜单 -->
            <el-dropdown class="user-dropdown">
                <div class="user-dropdown-link">
                    <el-avatar class="avatar">
                        <img src="../assets/user_avatar.jpg" alt="" />
                    </el-avatar>
                    <el-icon name="el-icon-arrow-down"></el-icon>
                </div>
                <el-dropdown-menu slot="dropdown">
                    <!-- 其他菜单项 -->
                    <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        logout() {
            localStorage.removeItem("token");
            this.$message.success("退出成功,正在跳转至登录页面");
            setTimeout(() => {
                this.$router.replace("/login");
            }, 1000);
        },
    },
};
</script>

<style scoped>
.header {
    padding: 10px 20px;
    color: #333; /* 更深的文字颜色 */
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.user-dropdown {
    margin-left: auto;
}
.user-dropdown-link {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.avatar {
    border: 1px solid #d3dce6;
}

/* 下拉菜单样式调整 */
.el-dropdown-menu {
    min-width: 120px;
}

.el-dropdown-item {
    color: #333;
}
.el-icon {
    margin-left: 5px; /* 调整图标与头像的间距 */
}
</style>
