import Vue from "vue";
import Router from "vue-router";
import layout from "../layout/layout.vue";

Vue.use(Router);
const publicRoutes = [
    {
        path: "/",
        redirect: "/list",
    },
    {
        path: "/editor",
        name: "editor",
        component: () => import("@/views/editorPage/editorPage.vue"),
        meta: {
            title: "编辑器",
        },
    },

    {
        path: "/login",
        name: "login",
        component: () => import("@/views/login/index.vue"),
        meta: {
            title: "登录",
        },
    },
];
export const routes = [
    {
        path: "/list",
        name: "list",
        redirect: "/link/list",
        component: layout,
        meta: {
            title: "链接管理",
            icon: "el-icon-s-unfold",
        },
        children: [
            {
                path: "/link/list",
                name: "link-list",
                component: () => import("@/views/list/index.vue"),
                meta: {
                    title: "链接列表",
                    icon: "el-icon-s-fold",
                },
            },
        ],
    },
];

const basicRoutes = publicRoutes.concat(routes);
const router = new Router({
    mode: "hash",
    base: process.env.BASE_URL,
    routes: basicRoutes,
});
router.beforeEach((to, _from, next) => {
    document.title = process.env.VUE_APP_TITLE + "-" + to.meta.title;
    const isLoggedIn = localStorage.getItem("token"); /* 检查登录状态的逻辑 */

    if (!isLoggedIn && to.path !== "/login") {
        // 如果用户未登录且目标路径不是登录页面，则重定向到登录页面
        next("/login");
    } else {
        // 否则正常进行路由跳转
        next();
    }
});
export default router;
